.dropdown {
  position: relative;
  color: #000000;
  cursor: default;
  border: 2px solid black;
  height:28px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.dropdown .arrow {
  border-color: black transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  position: absolute;
  right: 10px;
  top: 12px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: black transparent transparent;
  border-width: 5px 5px 0;
}

.dropdown .selected-value input {
  font-size: 14px;
  border: transparent;
  box-sizing: border-box;
  cursor: default;
  padding: 8px 32px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
  height: 28px;
}

.dropdown input:focus{
  border-radius: 0px;
}



.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid black;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: block;
  padding: 2px 10px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: rgb(24,144,255);
  color: white;
}

.dropdown ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

.dropdown :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}

.dropdown ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
}
